import React from 'react';
import { Image } from '@chakra-ui/image';
import {
  Divider,
  Flex,
  Heading,
  Link,
  Grid,
  SimpleGrid,
  Stack,
  Text,
  GridItem,
} from '@chakra-ui/layout';
import {
  Button,
  Icon,
} from '@chakra-ui/react'
import SectionWrapper from './SectionWrapper';
import footerLogo from '../images/footerLogo.svg';
import { navigationLinks, socialLinks } from '../utils/constant';
import ScrollAnimation from 'react-animate-on-scroll';
import scrollTo from 'gatsby-plugin-smoothscroll';
import FadeInCross from './FadeInCross';
import FadeInDownArrow from './FadeInDownArrow';

export default function Footer() {
  return (
    <SectionWrapper
      position={'relative'}
      bg='var(--chakra-colors-brown)'
      gap={12}
      paddingInline={[8, null, 16]}
      pb={32}
      >
      <FadeInCross
        place="right"
        bottom={96}
        showAbove="sm"
      />
      <Grid templateColumns={['repeat(1, 1fr)', null, null, 'repeat(3, 1fr)' ]} gap={12}>
        <GridItem colSpan={{ base: 1, lg: 3}}>
          <Image src={footerLogo} mr='auto'/>
        </GridItem>
        <Flex direction={'column'} gap={4}>
          <Heading textStyle="md" color="green.solid">
            About Us
          </Heading>
          <Text variant="subheading" color="white">
            Code Fixers is a service offered by Kickstand; a group of
            talented and dedicated software developers who love to help
            companies ship products.
          </Text>
        </Flex>
        <Flex direction={'column'} gap={4}>
          <Heading textStyle="md" color="green.solid">
            Sections
          </Heading>
          <SimpleGrid columns={{ base: 2, sm: 1, md: 2 }} spacingX={6} spacingY={2}>
            { navigationLinks.map(link => (
              <Text
                key={`nav-link-${link.id}`}
                color="white"
                variant="subheading"
              >
                {link.id ?
                <Link onClick={link.id ? () => scrollTo(`#${link.id}`) : () => {}}>{link.name}</Link>
                : <Link href={link.href}>{link.name}</Link>
                }
              </Text>
              )
            )}
          </SimpleGrid>
        </Flex>
        <Flex direction={'column'} gap={4}>
          <Heading textStyle="md" color="green.solid">
            Follow Us
          </Heading>
          <SimpleGrid columns={{ base: 2, sm: 1, md: 2 }} spacingX={6} spacingY={2}>
            {socialLinks.map(link => (
              <Link
                key={`social-link-${link.name}`}
                display="flex"
                alignItems="center"
                href={link.href}
                target="_blank"
                color='white'
                >
                <Icon as={link.icon} w="1em" h="1em"/>
                <Text
                  variant="subheading"
                  fontSize="inherit"
                  color='inherit'
                  pl={3}
                >{link.name}</Text>
              </Link>
              )
            )}
          </SimpleGrid>
        </Flex>
      </Grid>
      <Divider
        borderWidth={1}
        orientation="horizontal"
        />
      <Text
        color='yellow'
        fontSize="xs"
        textAlign="center"
        >
        Copyright © Code Fixers. All Rights Reserved
      </Text>
    </SectionWrapper>
  );
}
