import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Heading: ComponentStyleConfig = {
  baseStyle: {
    margin: 0,
    color: '#000',
    fontFamily: 'Squada One',
    fontWeight: 400,
    letterSpacing: '.01rem',
    textTransform: 'none',
    lineHeight: 1,
    maxWidth: '42ch'
  },
  //@ts-ignore setting this prop in order to apply global theme textStyle props
  sizes: null,
  defaultProps: {
    textStyle: 'xl'
  }
}

export const Text: ComponentStyleConfig = {
  baseStyle: {
    margin: 0,
    color: '#000',
    fontFamily: 'Roboto',
    fontWeight: 400,
    maxWidth: '68ch',
  },
  //@ts-ignore setting this prop in order to apply global theme textStyle props
  sizes: null,
  variants: {
    subheading: {
      fontFamily: 'Roboto Condensed'
    }
  },
  defaultProps: {
    textStyle: 'xs',
  }
}

export const Button: ComponentStyleConfig = {
  baseStyle: {
    cursor: 'pointer',
    height: '3.75rem',
    width: '12.5rem',
    borderRadius: '1.875rem',
    color: 'rgba(255, 255, 255, .8)',
    alignContent: 'center',
    fontFamily: 'Squada One',
    fontWeight: 400,
    letterSpacing: '.01rem',
    textStyle: 'sm',
    textTransform: 'uppercase',
    transition: 'transform .2s, color .1s',
    _hover: {
      color: 'var(--chakra-colors-white)',
      transform: 'translate(0px, -6px)'
    }
  },
  //@ts-ignore setting this prop in order to apply global theme textStyle props
  sizes: null,
  variants: {
    green: {
      background: 'green.solid',
      boxShadow: '0px 5px var(--chakra-colors-green-tint)'
    },
    red: {
      background: '#DF4948',
      boxShadow: '0px 5px var(--chakra-colors-red-tint)'
      
    }, 
  },
  defaultProps: {
    variant: 'green'
  }
}