import React from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Show,
  Text,
} from '@chakra-ui/react';

import FadeInCross from './FadeInCross';
import FadeInDownArrow from './FadeInDownArrow';
import SectionWrapper from './SectionWrapper';
import codeImage from '../images/codeImage.png';
import herobgLg from '../images/herobg-lg.gif';
import herobg2xl from '../images/herobg-2xl.gif';
import logo from '../images/logo.svg';
import scrollTo from 'gatsby-plugin-smoothscroll';

const Header = () => {
  return (
    <SectionWrapper
      id="top"
      position='relative'
      backgroundImage={[herobgLg, null, herobg2xl]}
      backgroundRepeat="no-repeat"
      backgroundSize={{base: 'contain', xl: 'cover'}}
      backgroundColor='#FEFDC5'
      pt={8}
      textAlign="center"
    >
      <Flex
        className="animate__animated animate__fadeInUp"
        direction="column"
        alignItems="center"
        justifyContent="space-around"
        gap={12}
      >
        <Image
          src={logo}
          alt="Code Fixers Logo"
          />
        <Heading
          textStyle="xl"
          color="brown"
          textTransform="uppercase"
        >
          Thank you for signing up.
        </Heading>
        <Text
          variant="subheading"
          textStyle="md"
        >
          We will be in touch shortly with all the information to get started.
        </Text>
        <Flex
          alignItems='center'
          justifyContent={{ base: 'space-evenly', sm: 'space-around', md:'space-evenly'}}
          direction={{ base: 'column', sm:'row' }}
          gap={{ base: 12, sm: 8, md: 16 }}
          >
        </Flex>
      </Flex>
      <FadeInCross
        place="left"
        fade="left"
        bottom={{base: 96, md: 72}}
        showAbove="sm"
      />

    </SectionWrapper>
  );
};

export default Header;
