import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../styles/theme';
import '@fontsource/squada-one/400.css';
import '@fontsource/roboto';
import '@fontsource/roboto-condensed';
// import Faqs from './Faqs';
import FooterThanks from '../components/FooterThanks';
import HeaderThanks from '../components/HeaderThanks';
import { SEO } from '../components';

const IndexPage = () => {
  return (
    <ChakraProvider theme={theme}>
      <SEO />
      <HeaderThanks />
      <FooterThanks />
    </ChakraProvider>
  );
};

export default IndexPage;
