import { extendTheme } from "@chakra-ui/react";
import { Button, Heading, Text } from './styleConfig'

const theme = extendTheme({
  components: {
    Button,
    Heading,
    Text,
  },
  colors: {
    black: '#000',
    white: {
      solid: '#fff',
      tint: 'rgba(255, 255, 255, .8)'
    },
    red: {
      solid: 'rgba(223, 73, 72, 1)',
      tint: 'rgba(223, 73, 72, 0.2)'
    },
    green: {
      solid: 'rgba(77, 178, 144, 1)',
      tint: 'rgba(77, 178, 144, 0.2)'
    },
    brown: '#5B2E2E',
    grey: '#9295A0',
    yellow: '#FEFDC5'
  },
  fonts: {
    body: "Roboto",
  },
  styles: {
    global: {
      'body': {
        width: '100%',
      },
    },
  },
  textStyles: {
    //fluid font sizing for heading-level or title typography
    xs: { fontSize: "1rem" },
    sm: {
      fontSize: 'clamp(1.2rem, 0.2445vw + 1.1236rem, 1.414rem)',
    },
    md: {
      fontSize: 'clamp(1.44rem, 0.6387vw + 1.2405rem, 1.999rem)'
    },
    lg: {
      fontSize: 'clamp(1.728rem, 1.256vw + 1.3355rem, 2.827rem)'
    },
    xl: {
      fontSize: 'clamp(2.074rem, 2.1988vw + 1.387rem, 3.998rem)'
    },
    '2xl': {
      fontSize: 'clamp(2.426rem, 3.69vw + 1.27rem, 5.653rem)'
    }
  },
//clamp min @ 400, max @ 1796
  //temporarily adding Chakra's default breakpoints here for reference
  //@media is compiled using min-width, 'sm' =  0em-30em
  // breakpoints: {
  //   //480px
  //   sm: '30em', 
  //   //760px
  //   md: '48em',
  //   // 992px
  //   lg: '62em',
  //   // 1280px
  //     xl: '80em',
  //   //1536px
  //   '2xl': '96em',
  // }
});

export default theme;